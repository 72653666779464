<script lang="ts" setup>
defineProps<{ isShowBadgeActiveRaces: boolean; amountPromotions: number; isShowSeason?: boolean }>();
defineEmits<{ (event: "openSidebar"): void }>();

const { t } = useT();
const localPath = useLocalePath();
</script>
<template>
	<div class="mob-navigation">
		<AText as="div" variant="topeka" class="mob-item text-conakry" @click="$emit('openSidebar')">
			<NuxtIcon name="20/burger" filled />
			{{ t("Menu") }}
		</AText>
		<NuxtLink class="mob-item" :to="localPath('/issues/all-games/')">
			<AText variant="topeka" class="text-conakry">
				<NuxtIcon name="20/slots" filled />
				{{ t("Games") }}
			</AText>
		</NuxtLink>
		<NuxtLink v-if="!isShowSeason" class="mob-item" :to="localPath('/races/')">
			<ABadge v-if="isShowBadgeActiveRaces" class="counter">
				<AText class="text-chaozhou" variant="ternopil">1</AText>
			</ABadge>
			<AText variant="topeka" class="text-conakry">
				<NuxtIcon name="20/race-next-active" filled />
				{{ t("Races") }}
			</AText>
		</NuxtLink>
		<LazyOSeasonProgress v-if="isShowSeason" />
		<LazyONotificationCenter :enabled-popup="false" />
		<NuxtLink class="link mob-item" :to="localPath('/promotions/')">
			<ABadge v-if="amountPromotions > 0" class="counter">
				<AText class="text-chaozhou" variant="ternopil">{{ amountPromotions }}</AText>
			</ABadge>
			<AText variant="topeka" class="text-conakry">
				<NuxtIcon name="20/bonus" filled />
				{{ t("Promo") }}
			</AText>
		</NuxtLink>
	</div>
</template>
<style scoped lang="scss">
.mob-navigation {
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 56px;
	background-color: var(--casablanca);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: gutter(1) gutter(2);
	backdrop-filter: blur(11px);
	z-index: 11;

	.notification-center {
		max-width: 42px;
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}
.mob-item,
.mob-item > span {
	display: flex;
	flex-direction: column;
	gap: gutter(0.25);
	align-items: center;
	min-width: 40px;
	position: relative;

	.nuxt-icon {
		display: inline-flex;
		font-size: 20px;
		width: 20px;
		height: 20px;
		text-align: center;
		vertical-align: middle;

		&:deep(svg) {
			margin: 0;
		}
	}

	&.active {
		color: var(--cannes);

		.nuxt-icon {
			font-size: 20px;

			&:deep(path:not([fill])) {
				stroke: var(--cannes);
			}
			&:deep(path[fill]) {
				fill: var(--cannes);
			}
		}
	}

	.badge {
		position: absolute;
		z-index: 1;
		top: -4px;
		right: 4px;
	}
}
</style>
